import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.neos-nodetypes-text-with-image ')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  //   smoothScroll('a[href^="#"]', {offset: 400})

  const unmuteCollection = document.createElement('div')
  unmuteCollection.classList.add('unmute-wrapper')

  const div = document.createElement('div')
  div.classList.add('unmute-btn')

  const pulse = document.createElement('div')
  pulse.classList.add('pulse')

  unmuteCollection.appendChild(div)
  unmuteCollection.appendChild(pulse)

  const element = document.getElementById('video-plyr')
  element.appendChild(unmuteCollection)

  $('.unmute-wrapper').click(function () {
    if ($('video').prop('muted')) {
      $('video').prop('muted', false)
      $('.unmute-btn').addClass('unmute-icon')
    } else {
      $('video').prop('muted', true)
      $('.unmute-btn').removeClass('unmute-icon')
    }
  })
})

$(document).ready(function () {
  if ($(window).width() <= 768) {
    $(window).scroll(function () {
      const scroll = $(window).scrollTop()
      if (scroll > 0) {
        $('#nav').addClass('active')
      } else {
        $('#nav').removeClass('active')
      }
    })
  } else {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop()
      if (scroll > 100) {
        $('#nav').addClass('active')
      } else {
        $('#nav').removeClass('active')
      }
    })
  }
})

if (window.location.pathname == '/referenzen') {
  $('.callout').addClass('no-sub')
}
const newLocal = window.location.pathname == '/kontakt'
if (newLocal) {
  $('.callout').addClass('no-sub')
}
// Enable tabbable milestone tabs by activating it

const triggerTabList = [].slice.call(document.querySelectorAll('#year-tab li:first-child button'))
triggerTabList.forEach(function (triggerEl) {
  const tabTrigger = new bootstrap.Tab(triggerEl)

  triggerEl.addEventListener('click', function (event) {
    event.preventDefault()
    tabTrigger.show()
  })
})

$('.tab-content div:first').addClass('show active')
$('#year-tab li button:first').addClass('active')

$('.team-slider .neos-contentcollection').slick({
  dots: true,
  infinite: false,
  arrows: true,
  touchMove: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
})

$('.carousel').carousel({
  interval: false
})

$('.job-wrapper .arrow').click(function () {
  $('.job-wrapper').toggleClass('close')
})

$(window).scroll(function () {
  if ($(this).scrollTop() >= 600) {
    $('.job-wrapper').fadeIn(400).addClass('visible')
  } else {
    $('.job-wrapper').fadeOut(400).removeClass('visible')
  }
})
